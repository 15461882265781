/* Box sizing rules */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

.displaynone{
  display: none;
}

body{
  font-weight: 800;
}


/* RGB文字效果使用說明 */
/* 
  基本架構
  <div class="RGB_effect">
    <span>RGB_effect</span>
  </div>
  文字部分需要給予背景顏色，不然文字會不見
*/
.RGB_effect > span{
  color: transparent;
  background-clip: text;
}

.RGB_effect:hover span{    
  background-image: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
  background-size: 400%;
  animation: bgimove 10s linear infinite;
}

@keyframes bgimove {
  0% {
    background-position: 0 0;
  }
  50%{
    background-position: 300% 0;
  }
  100% {
    background-position: 0 0;
  }
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #ccc;
}